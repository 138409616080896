WebFont.load({
    google:{
        families: ['Nunito:300,400,700,800&display=swap']
    },
    custom: {
        families: ['FontAwesome'],
        urls: [
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
        ]
    }
});

(function ($) {
    window.lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        threshold: 100
    });

    window.real_hotels_refresh_size_queries = window.real_hotels_refresh_size_queries || function() {
        var classes = [];
        var scrollbarwidth = real_hotels_getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        window.is_phone = (window_width < 768);
        window.is_mobile = (window_width < 992);
        window.is_tablet_portrait = (window_width >= 768 && window_width < 992);
        window.is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        window.is_tablet = is_tablet_portrait || is_tablet_landscape;
        window.is_desktop = (window_width >= 992);
        window.is_desktop_large = (window_width >= 1200);

        if (window.is_phone) {
            classes.push('mq_phone');
        }
        if (window.is_mobile) {
            classes.push('mq_mobile');
        }
        if (window.is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (window.is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (window.is_tablet) {
            classes.push('mq_tablet');
        }
        if (window.is_desktop) {
            classes.push('mq_desktop');
        }
        if (window.is_desktop_large) {
            classes.push('mq_desktop_large');
        }


        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');

        $('html').addClass(classes.join(' '));

    }

    function real_hotels_getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function real_hotels_custom_scrollbar_bigmenu(){
        if(!is_mobile){
            var primary_menu = $('.big-menu-content  #primary_menu'),
                primary_menu_totalH = 0,
                primary_menuH = primary_menu.height();
            primary_menu.find('nav li').each(function(){
                primary_menu_totalH += $(this).outerHeight();
            });

            if(primary_menu_totalH >= primary_menuH){
                primary_menu.mCustomScrollbar({
                    theme:"dark",
                    axis:"y"
                });
            }
        }
        if(is_mobile){
            $('#mobile_menu').mCustomScrollbar({
                theme:"dark",
                axis:"y"
            });
        }
    }

    function real_hotels_AOS() {
        AOS.init({
            duration: 1600,
            disable: function () {
                var maxWidth = 992;
                return window.innerWidth < maxWidth;
            }
        });
    }

    function real_hotels_dynamic_calendar(){
        var btns = $('#open_dynamic_calendar,.big-menu-wrapper .box.main-book, #main_book_mobile');
        var calendar_wrapper = $('#dyncal_wrapper');
        btns.click(function () {
            if (!calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.addClass('visible');
                $('html').addClass('no-scroll');
            }
        });

        $('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');
                $('html').removeClass('no-scroll');
            }
        });
    }

    function reals_hotels_seo_position() {
        $('.seobar-wrapper').appendTo($('#seobar_content'));
    }

    function real_hotels_header_position() {
        var header = $('.header-wrapper'),
            fixed = 'fixed',
            breakpoint = header.height(),
            scroll = $(window).scrollTop();
        if (scroll > breakpoint) {
            header.addClass(fixed);
        } else {
            header.removeClass(fixed);
        }
    }

    function real_hotels_slideshow(){
        $('.slideshow').not('.slick-initialized').slick({
            rows:0,
            speed: slideshow_speed,
            autoplaySpeed: slideshow_autoplay_speed,
            arrows: false,
            dots: true,
            autoplay: true,
            fade: true,
            pauseOnHover: false,
            pauseOnFocus: false,
            waitForAnimate: false,
            prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
            nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
            dotsClass: 'dots-slideshow',
            lazyLoad: 'progressive',
            customPaging : function(slider, i) {
                return '<a href="javascript:;"></a>';
            },
            responsive:[
                {
                    breakpoint: 992,
                    settings: {
                        arrows: true,
                        dots: false,
                    }
                }
            ]
        });
    }

    function real_hotels_video(){
        if($('html').hasClass('home')){
            var player_icon = $('.play-icon-video');
            if(player_icon.length > 0){
                var player = new Plyr('#player', {
                    volume:0.5,
                    controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
                    previewThumbnails: true
                });

                if(enable_video_loop != false){
                    if(is_desktop){
                        var controls_loop_video = $('.controls-video-loop #icon');
                        player.volume = 0;
                        player.autoplay = true;
                        player.loop = true;
                        player.volume;
                        player.loop;
                        player.autoplay;
                        $('.slideshow').slick('slickPause');
                        $('.slideshow-container').addClass('playing-video');
                        setTimeout(function(){
                            $('.slideshow-container').css({
                                'height' : $('#player').height()
                            });
                        },100);
                        $('.player_wrapper').fadeIn('fast');
                        player.play();
                        controls_loop_video.on('click', function(){
                            if($(this).hasClass('pause')){
                                $(this).toggleClass('pause play');
                                player.pause();
                            } else {
                                $(this).toggleClass('play pause');
                                player.play();
                            }
                        });
                    }
                }

                player_icon.on('click', function(){
                    $('.slideshow').slick('slickPause');
                    $('.slideshow-container').addClass('playing-video');
                    $('.player_wrapper').fadeIn('fast');
                    setTimeout(function (){
                        $('.slideshow-container').css({
                            'height' : $('#player').height()
                        });
                        player.play();
                    },1000);

                    player.on('ended', function(){
                        $('.slideshow-container').removeClass('playing-video');
                        $('.player_wrapper').fadeOut('fast');
                        player.stop();
                        $('.slideshow-container').removeAttr('style');
                        $('.slideshow').slick('slickPlay');
                    });
                });

                $('.controls a.close').on('click', function () {
                    $('.slideshow-container').removeClass('playing-video');
                    player.stop();
                    $('.slideshow-container').removeAttr('style');
                    $('.player_wrapper').fadeOut('fast');
                    $('.slideshow').slick('slickPlay');
                });
            }
        }
    }

    function real_hotels_default_fancybox(){
        $('[data-fancybox]:not(.slick-cloned)').fancybox({
            buttons: [
                "close"
            ],
            btnTpl:{
                smallBtn: '<button type="button" data-fancybox-close class="fancybox-button close colored"></button>',
                close: '<button type="button" data-fancybox-close class="fancybox-button close inverse"></button>'
            }

        });
    }

    function real_hotels_manual_fancybox() {
        $('a[data-manual-fancy]').on('click',function () {
            var button = $(this);
            var data = button.data('src');
            if (!data) {
                return;
            }
            var html = $(data).text();
            $.fancybox.open(html, {
                'afterShow': function () {
                    if (typeof renderRecaptcha !== 'undefined') {
                        renderRecaptcha();
                    }
                    if (typeof gformInitDatepicker !== 'undefined') {
                        gformInitDatepicker();
                    }
                },
                autoFocus: false,
                arrows: false,
                infobar: false,
                btnTpl:{
                    smallBtn: '<button type="button" data-fancybox-close class="fancybox-button close colored"></button>'
                }
                });

        })
    }

    function real_hotels_boxes_section(){
        var gallery_container = $('.boxes-section .single-item .thumb-wrap.gallery');
        gallery_container.each(function(){
            $(this).not('.slick-initialized').slick({
               rows: 0,
               prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
               nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
            });
        });
    }

    function real_hotels_tab_services(){
        var tab_wrapper = $('.tab-services'),
        tabs_mobile_select = tab_wrapper.find('.tabs-mobile-select'),
        single_tab = tab_wrapper.find(".single-tab");
        if(tab_wrapper.length > 0){
            $(".single-tab:first").show();
            if(is_desktop){
                $("ul.tabs li:first").addClass("active").show();

                $("ul.tabs li").click(function() {
                    $("ul.tabs li").removeClass("active");
                    $(this).addClass("active");
                    $(".single-tab").hide();
                    var activeTab = $(this).find("a").attr("href");
                    $(activeTab).fadeIn();
                    return false;
                });
            }
            if(is_mobile){
                tabs_mobile_select.on('change', function(){
                    var value = $(this).val();
                    single_tab.each(function(){
                        value === $(this).attr('id') ? $(this).fadeIn() : $(this).fadeOut();
                    });
                });
            }
        }
    }

    function real_hotels_minigallery(){
        var minigallery_caorusel = $('.minigallery-carousel');
        if(minigallery_caorusel.length > 0){
            minigallery_caorusel.on('init', function(event, slick){
                lazyLoadInstance.update();
            });
            minigallery_caorusel.not('.slick-initialized').slick({
                rows:0,
                slidesToShow:1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '0',
                variableWidth: true,
                prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
            });
        }
    }

    function real_hotels_siblings(){
        var siblings_carousel = $('.siblings-carousel, .preview-offers-carousel'),
        children = siblings_carousel.children(),
        count_items = children.length,
        hasCarousel = false;

        if(siblings_carousel.length > 0){
            if(is_mobile && count_items > 1){
                hasCarousel = true
            } else {
                if(count_items > 3){
                    hasCarousel = true
                }
            }
            if(hasCarousel){
                siblings_carousel.on('init', function(event, slick){
                    lazyLoadInstance.update();
                });
                siblings_carousel.not('.slick-initialized').slick({
                    rows: 0,
                    slidesToShow:3,
                    slidesToScroll: 1,
                    prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }

        }

    }

    function real_hotels_popup(){
        if ($('#popup_wrapper').length > 0) {
            $.fancybox.open({
                src: '#popup_wrapper',
                type: 'inline',
                touch: false,
                autoFocus: false,
                smallBtn : true,
                baseClass: "popup-fancybox",
                afterLoad: function () {
                    lazyLoadInstance.update();
                }
            },
            {
                btnTpl:{
                    smallBtn: '<button type="button" data-fancybox-close class="fancybox-button close colored"></button>'
                }
            });
        }
    }
    function real_hotels_preview_rooms_home() {
        if($('html').hasClass('homepage')){
            var prev_rooms_carousel = $('.rooms-carousel');
            if(prev_rooms_carousel.length > 0){
                prev_rooms_carousel.on('init', function(event, slick){
                    lazyLoadInstance.update();
                });
                prev_rooms_carousel.not('.slick-initialized').slick({
                    rows: 0,
                    dots: true,
                    prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                    dotsClass: 'custom-pagination',
                    appendDots: $('.custom-pagination-container'),
                    customPaging: function(slick,index) {
                        var slide = slick.$slides[index],
                            pagination = $(slide).data('title');
                        return '<a href="javascript:;">' + pagination + '</a>';
                    }
                });
            }
        }

    }

    function real_hotels_choose_us_home(){
        var choose_service_wrapper = $('.choose-us-services-content'),
        children = choose_service_wrapper.children(),
            num_boxes = children.length,
            hasCarousel = false;
        if(choose_service_wrapper.length > 0){
            if(is_mobile && num_boxes > 0){
                hasCarousel = true;
            } else{
                if(num_boxes > 3){
                    hasCarousel = true;
                }
            }
            if(hasCarousel){
                choose_service_wrapper.not('.slick-initialized').slick({
                    rows: 0,
                    slidesToShow:4,
                    slidesToScroll: 1,
                    arrows:false,
                    dots: true,
                    infinite: false,
                    prevArrow: '<a class="slick-arrow inverse prev" href="javascript:;"><span></span></a>',
                    nextArrow: '<a class="slick-arrow inverse next" href="javascript:;"><span></span></a>',
                    dotsClass: 'slick-dots right',
                    appendDots: '.choose-us-services .buttons .dots-container',
                    customPaging : function(slider, i) {
                        return '<a href="javascript:;"></a>';
                    },
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                dots: false,
                                arrows:true
                            }
                        }
                    ]
                });
            }
        }

    }

    function real_hotels_preview_pages_home(){
        var preview_pages_hp_wrapper = $('.preview-pages-carousel, .preview-offers-room-carousel, .preview-spa-offers-carousel'),
            children = preview_pages_hp_wrapper.children(),
            num_preview = children.length,
            hasCarousel = false;
        if(is_mobile && num_preview > 0){
            hasCarousel = true;
        } else {
            if(num_preview > 3){
                hasCarousel = true;
            }
        }

        if(hasCarousel){
            preview_pages_hp_wrapper.on('init', function(event, slick){
                lazyLoadInstance.update();
            });

            preview_pages_hp_wrapper.not('.slick-initialized').slick({
                rows: 0,
                slidesToShow:3,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: 0,
                prevArrow: '<a class="slick-arrow prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow next" href="javascript:;"><span></span></a>',
                responsive:[
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            centerPadding: 25,
                            arrows: false,
                            dots: true,
                            customPaging : function(slider, i) {
                                return '<a href="javascript:;"></a>';
                            },
                        }
                    }
                ]
            });
        }
    }

    function real_hotels_social_media(){
        var soc_media_carousel = $('.social-media-container'),
        images = soc_media_carousel.children(),
        num_img = images.length,
        hasCarousel = false;
        if(is_mobile && num_img > 0){
            hasCarousel = true;
        } else {
            if(num_img > 2){
                hasCarousel = true;
            }
        }

        if(soc_media_carousel.length > 0 && hasCarousel){
            soc_media_carousel.on('init', function(event, slick){
                lazyLoadInstance.update();
            });
            soc_media_carousel.not('.slick-initialized').slick({
                rows:0,
                slidesToShow: 3,
                slidesToScroll: 1,
                speed: 3000,
                autoplaySpeed: 3500,
                arrows: true,
                dots: false,
                autoplay: true,
                pauseOnHover: false,
                pauseOnFocus: false,
                waitForAnimate: false,
                prevArrow: '<a class="slick-arrow transparent prev" href="javascript:;"><span></span></a>',
                nextArrow: '<a class="slick-arrow transparent next" href="javascript:;"><span></span></a>',
                responsive:[
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            arrows: false,
                            dots: false,
                        }
                    }
                ]
            });
        }
    }


    function real_hotels_big_menu(){
        var hamburger_menu = $('.open-menu'),
            big_menu = $('.big-menu-wrapper'),
            close_btn = big_menu.find('.close-btn');
        hamburger_menu.on('click', function(){
            $('html').addClass('no-scroll');
            big_menu.addClass('open');
        });

        close_btn.on('click', function(){
            $('html').removeClass('no-scroll');
            big_menu.removeClass('open');
        });
    }

    function real_hotels_sidebar_height(){
        if($('html').hasClass('special-offers-page') ||
            $('html').hasClass('gift-voucher-page') ||
            $('html').hasClass('shows-page')){
            var sidebar = $('.filters-offers');
            if(sidebar.length > 0 && !is_mobile){
                var wh = $(window).height(),
                    breakpoint = $('.header-wrapper').outerHeight(),
                    ribbon = $('._dedge-ribbon');
                if ($(window).scrollTop() >= breakpoint){
                    if(ribbon.length > 0){
                        var height_sidebar = wh - $('.header-wrapper.fixed').outerHeight() - ribbon.height();
                    } else{
                        var height_sidebar = wh - $('.header-wrapper.fixed').outerHeight();
                    }
                } else{
                    if(ribbon.length > 0){
                        var height_sidebar = wh - $('.header-wrapper').outerHeight() - ribbon.height();
                    } else {
                        var height_sidebar = wh - $('.header-wrapper').outerHeight();
                    }
                }

                sidebar.css({
                    height: height_sidebar
                });

                sidebar.parent().find('.offers-container').css({
                    'min-height': height_sidebar
                });

                $(window).scroll(function () {
                    var bt = $('.footer').offset().top,
                        bottom = (Math.floor(bt - $(window).scrollTop() - wh)) * -1;
                    if (bottom >= $('.header-wrapper.fixed').outerHeight()) {
                        sidebar.css({
                            bottom: bottom
                        })
                    } else {
                        sidebar.css({
                            bottom: 0
                        })
                    }
                })
            }
        }
    }

    function real_hotels_single_offer(){
        if($('html').hasClass('single-post_offer')){

            var offer_container_wrap = $('.offer-container'),
                offer_container = $('.offer-container .offer-wrap'),
                side_thumb = offer_container_wrap.find('.thumb-wrap');

            if(offer_container_wrap.length > 0 && !is_mobile){
                // check if scrolled
                var w_scrolled = $(window).scrollTop();
                var w_height = $(window).height();
                var breakpoint = $('.header-wrapper').height();
                var side_content_height = offer_container.height();
                var page_bottom_limit = offer_container_wrap.outerHeight()+offer_container_wrap.offset().top;
                var window_bottom_limit = $(window).outerHeight()+w_scrolled;

                if ($(window).scrollTop() >= breakpoint){
                    var height_content = $('.header-wrapper.fixed').height();
                } else{
                    var height_content = $('.header-wrapper').height();
                }

                // check if content is more than page visible
                side_thumb.css({
                    height : w_height - height_content
                });
                if((w_height-height_content) > side_content_height){
                    side_thumb.addClass('absolute').css({top:'auto',bottom:'auto'});
                } else {
                    side_thumb.removeClass('absolute');
                    // if the limit of the page is less than the limit of the window scrolled
                    if(page_bottom_limit<window_bottom_limit){
                        var bottom = window_bottom_limit-page_bottom_limit;
                        side_thumb.css({top:'auto',bottom:bottom+'px'});
                    } else {
                        side_thumb.css({top:'100px',bottom:'auto'});
                    }
                }

            }
        }
    }

    function real_hotels_offer_term_conditions(){
        var terms_condition_wrapper = $('.offer-terms-conditions'),
            single_term = $('.offer-terms-conditions .single-term');

        if($('html').hasClass('single-post_offer') && terms_condition_wrapper.length > 0){

            single_term.each(function(){
                var single_item = $(this),
                    single_term_label = single_item.find('a.label'),
                    single_term_content_height = single_item.find('.term-content').outerHeight(true);

                single_term_label.on('click', function(){

                    var siblings = single_item.siblings('.single-term'),
                        siblings_label = siblings.find('a.label');

                    if(!$(this).hasClass('open')){
                        $(this).addClass('open');
                        single_item.find('.term-container').css({
                            height: single_term_content_height
                        });
                    } else{
                        $(this).removeClass('open');
                        single_item.find('.term-container').css({
                            height: 0
                        });
                    }

                    if(siblings_label.hasClass('open')){
                        siblings.find('.term-container').css({
                            height: 0
                        });
                        siblings_label.removeClass('open')
                    }
                });
            });
        }
    }

    function real_hotels_web_sdk_offers(){
        if(!$('body').hasClass('roiback-be-enabled')){
            $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
            $('div[data-websdk]').each(function () {
                var container = $(this),
                    model_name = container.data('websdk'),
                    id = container.attr('id'),
                    custom_template = container.data('websdk-template'),
                    template_id, config, model, template, html;
                template_id = custom_template ? custom_template : '#' + id + '_template';
                template = $(template_id).text();
                if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined') {
                    config = websdk_config[id];
                    $fbsdk.baseHost = config.baseHost;
                    model = new $fbsdk[model_name](config.params);
                    model.setAuthToken(config._authCode);
                    model.load(function (error) {
                        var data, i;
                        if (!error) {
                            //YEAH, this happens when we have data and we're ready to use it!
                            data = JSON.parse(JSON.stringify(this.getRawData()));
                            if (model_name == 'Offers' && data.rates.length == 0) {
                                container.addClass('no_offers');
                                if($('html').hasClass('single_room_page') && container.hasClass('hidden-if-empty')){
                                    container.closest('.section').hide();
                                }
                                container.closest('.special_offers_row').find('.hotel .price').remove();
                            }
                            container.closest('.loader').addClass(' processed noloader');

                            if (typeof data.rates !== 'undefined') {

                                if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers !== '') {
                                    var temp_rates = [];
                                    for (var i = 0; i < data.rates.length; i++) {
                                        if (typeof data.rates[i] !== 'undefined') {
                                            if ($.inArray(data.rates[i].rate.name, config.excluded_offers) == -1) {
                                                // if is allowed
                                                temp_rates.push(data.rates[i]);
                                            }
                                        }
                                    }
                                    // OVERRRIDE THE OBJ
                                    data.rates = temp_rates;
                                }

                                // limit numbers
                                if (typeof config.offers_number !== 'undefined' && config.offers_number !== '') {
                                    if(data.rates.length > parseInt(config.offers_number, 10)) {
                                        data.rates.length = parseInt(config.offers_number, 10);
                                    }
                                }



                                if(typeof config['putBookLink'] !== 'undefined'){
                                    var bookLink = false;
                                    for(var i =0; i < data.rates.length; i++){
                                        if(config['rateName'] === data.rates[i].rate.name){
                                            bookLink = data.rates[i].quotation.jsBookLink;
                                        }
                                    }
                                    if(bookLink){
                                        container.closest(config['putBookLink'].closest).find(config['putBookLink'].find).attr('onclick', bookLink);
                                    }
                                }

                                for (var i = 0; i < data.rates.length; i++) {
                                    if (i % 2 == 0) {
                                        data.rates[i].rate.classname = 'even';
                                    } else {
                                        data.rates[i].rate.classname = 'odd';
                                    }
                                    data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 120) + '...';
                                }
                            }

                            html = Mustache.render(template, data);
                            container.html(html);

                            //da qui in poi ho  HTML delle offerte nella pagina
                        } else {
                            // in caso di errore
                            container.fadeOut();
                        }
                    });
                }
            });
        }
    }

    function real_hotels_web_sdk_preloader(){
        if(!$('body').hasClass('roiback-be-enabled')){
            $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
            $('div[data-websdkpreload]').each(function () {
                var container = $(this),
                    config = container.data(),
                    model_name = config.websdkpreload,
                    id = container.attr('id'),
                    params =  {
                        property: config.hid,
                        locale: config.locale,
                        _authCode: config.authcode,
                        currency: config.currency,
                        minAdults: config.minadults
                    },
                    model;
                    if(config.roomrestriction){
                        params.roomRestriction = config.roomrestriction;
                    }
                    model = new $fbsdk[model_name](params);

                    model.setAuthToken(config.authcode);
                    model.load(function (error) {
                        var data, i;
                        if (!error) {
                            //YEAH, this happens when we have data and we're ready to use it!
                            data = JSON.parse(JSON.stringify(this.getRawData()));
                            var payload = {
                                config: config,
                                data: data,
                            }

                            container.trigger('websdk_loaded',payload);

                        } else {
                            // in caso di errore
                            container.trigger('websdk_error', error);
                        }
                    });
            });
        }
    }

    function real_hotels_handle_offer_book(){
        if(!$('body').hasClass('roiback-be-enabled')){
            var containers = $('.websdk-preloaded');

            containers.on('websdk_loaded', function(e, payload){
                if(payload && payload.data && payload.data.rates.length > 0){
                    var offer = $('.single-child .starting-from-container.websdk, .single-preview .starting-from-container.websdk');
                    for(var i = 0; i < payload.data.rates.length; i++){
                        var rate_name = payload.data.rates[i].rate.name;
                        if(offer.length > 0){
                            offer.map(function() {
                                if(rate_name === $(this).data('ratename')){
                                    $(this).find('.price').html(payload.data.rates[i].quotation.totalPrice);
                                }
                            });
                        }
                    }

                    $(this).data('offers',payload.data.rates);
                    $(this).addClass('offers-available');
                }
            });

            $(document).on('click', '.websdk-preloaded .button.websdk', function(){
                var button = $(this),
                    container = button.closest('[data-websdkpreload]'),
                    rates = container.data('offers'),
                    rate_name = button.data('ratename');
                if(rate_name && typeof rates != 'undefined' && rates.length > 0){
                    var filtered = rates.filter(function(rate){
                        return rate.rate.name === rate_name;
                    });
                    if(filtered.length > 0){
                        eval(filtered[0].quotation.jsBookLink.replace(/^javascript:/,''));
                        return;
                    }
                }

                $('#open_dynamic_calendar').trigger('click');
            });
        }
    }

    function real_hotels_header_hotels_list() {

        var hotels_list_header = $('.others-hotels');

        if(hotels_list_header.length > 0){
            hotels_list_header.find('.others-hotels-content').mCustomScrollbar({
                theme:"dark",
                axis:"y"
            });
        }
    }

    function real_hotels_anchor_newsletter(){
        var hash = window.location.hash;
        if (hash === "#newsletter_section") {
            var top = $("#newsletter_section").offset().top;
            $("body,html").animate(
                {
                    scrollTop: top - 70
                },
                1500,
                'easeOutQuart'
            );
        }
    }

    function real_hotels_dailypoint_newsletter(){
        var newsletter_module = $('#optin_form');
        if(newsletter_module.length > 0){
            var prefix_selector = $('#prefix_select'),
                language_selector = $('#lang_selector');

            //Before Remove all option
            prefix_selector.empty();

            //Populate the option of select in base of language of the wesbite
            if(icl_lang === 'pt-pt'){
                var options = ['Senhor','Senhora'];
                for(var i = 0; i < options.length; i++) {
                    var opt = options[i];
                    prefix_selector.append("<option value='" + opt + "'>" + opt + "</option>");
                }
            } else {
                var options = ['Mr','Mrs', 'Ms'];
                for(var i = 0; i < options.length; i++) {
                    var opt = options[i];
                    prefix_selector.append("<option value='" + opt + "'>" + opt + "</option>");
                }

            }

            //On change of the language selector update the selector prefix with new option
            language_selector.on('change', function(){
                var lang_value = $(this).val();
                if(lang_value === 'Portuguese'){
                    var new_option ="<option value='Senhor'>Senhor</option><option value='Senhora'>Senhora</option>";
                    prefix_selector.empty().append(new_option);
                } else {
                    var new_option ="<option value='Mr'>Mr</option><option value='Mrs'>Mrs</option><option value='Ms'>Ms</option>";
                    prefix_selector.empty().append(new_option);
                }
            });
        }
    }


    //Load Window
    $(window).on('load', function () {
        real_hotels_AOS();
        real_hotels_refresh_size_queries();
        real_hotels_custom_scrollbar_bigmenu();
        real_hotels_header_hotels_list();
        real_hotels_anchor_newsletter();
    });

    //Resize Window
    $(window).resize(function () {
        real_hotels_refresh_size_queries();
        real_hotels_custom_scrollbar_bigmenu();
        real_hotels_single_offer();
        shittyCalculateWidth();
        if($('.slideshow-container.playing-video').length > 0){
            $('.slideshow-container').css({
                'height' : $('#player').height()
            });
        }
    });

    //Dom ready
    $(document).ready(function () {
        real_hotels_refresh_size_queries();
        real_hotels_dynamic_calendar();
        reals_hotels_seo_position();
        real_hotels_header_position();
        real_hotels_slideshow();
        real_hotels_video();
        real_hotels_default_fancybox();
        real_hotels_manual_fancybox();
        real_hotels_boxes_section();
        real_hotels_tab_services();
        real_hotels_minigallery();
        real_hotels_siblings();
        real_hotels_preview_rooms_home();
        real_hotels_choose_us_home();
        real_hotels_preview_pages_home();
        real_hotels_sidebar_height();
        real_hotels_web_sdk_offers();
        real_hotels_web_sdk_preloader();
        real_hotels_handle_offer_book();
        real_hotels_offer_term_conditions();
        real_hotels_social_media();
        real_hotels_big_menu();
        real_hotels_popup();
        real_hotels_single_offer();
        real_hotels_dailypoint_newsletter();
        window.onscroll = function () {
            real_hotels_header_position();
            real_hotels_sidebar_height();
            real_hotels_single_offer();
        };
    });

})(jQuery);